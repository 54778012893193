/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown button */
.dropdown button {
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

/* Dropdown button hover */
.dropdown button:hover {
    background-color: #45a049;
}

/* Dropdown content container */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Dropdown item */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Dropdown item hover */
.dropdown-content a:hover {
    background-color: #f1f1f1;
}

/* Show dropdown content */
.dropdown:hover .dropdown-content {
    display: block;
}

.menu-wrapper {
    display: flex;
    flex-direction: column;
    top: 50px;
    right: 50px;
    position: absolute;
    padding: 15px;
    gap: 10px;
    z-index: 1;

}

.modern-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.modern-button:hover {
    background-color: #45a049;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.hamburger-icon {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 120px;
    right: 12px;
    z-index: 2;
}

.hamburger-icon:hover div {
    background-color: #888888;
}

.hamburger-icon div {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Hide the hamburger menu and menu-wrapper by default
.hamburger-icon {
    display: none;
} */

/* Show the hamburger menu and hide the buttons on mobile screens */
@media (max-width: 768px) {
    .hamburger-icon {
        display: block;
    }

    .menu-wrapper {
        display: none;
    }
}



/* Show the hamburger menu and hide the buttons on mobile screens */
@media (min-width: 768px) {
    .menu-wrapper {
        display: flex;
    }

    .hamburger-icon {
        display: none;
    }
}

/* Show the menu-wrapper when the visible class is added */
.menu-wrapper.visible {
    display: flex;
}

/* Checkbox container */
.time-filter {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

/* Checkbox label */
.time-filter label {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;
}

/* Checkbox input */
.time-filter input[type="checkbox"] {
    margin-right: 8px;
}

.time-filter .last-hour .color-box {
    background-color: red;
}

.time-filter .last-4-hours .color-box {
    background-color: orange;
}

.time-filter .last-8-hours .color-box {
    background-color: yellow;
}

.time-filter .last-24-hours .color-box {
    background-color: green;
}

/* Color box style */
.time-filter .color-box {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.logo {
    /* display: block; */
    max-width: 170px;
    /* Adjust the width as needed */
    margin-top: 10px;
}

.login-modal {
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.bottom-right-button {
    position: fixed;
    bottom: 20px;
    right: 60px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    color: #333;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.bottom-right-button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.touch-screen-overlay {
    position: fixed;
    top: 25%;
    left: 20%;
    width: 80%;
    height: 50%;
    background-color: rgba(255, 187, 0, 0.938);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    flex-direction: column;


}

.touch-screen-overlay h2 {
    font-size: 150px;
    color: #333;
    text-align: center;
}

.touch-screen-overlay .flicker-icon {
    animation: flash 2s infinite;
}

@keyframes flash {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.carousel-overlay {
    position: fixed;
    left: 0;
    width: 20%;
    border: 3px solid black;
    border-radius: 5px;
}

.carousel-info {
    /* position: absolute; */
    top: 0;
    left: 0;
    color: white;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    /* semi-transparent black */
}

.carousel-item {
    position: relative;
    padding-bottom: 20px;
}