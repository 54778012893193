.siteheader_container {
  z-index: 10000;
  height: 60px;
  background-color: #202123;
  border-radius: 0px;
  box-shadow: rgb(0 0 0 / 5%) 0px 8px 20px 0px;
  padding: 10px;
}

.siteheader_container.light {
  background-color: white;
}

.siteheader_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  /* pointer-events: none; */


}

.siteheader_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* pointer-events: none; */

}

.siteheader_box.group {
  /* Put a gap between each item */
  gap: 15px;
  pointer-events: none;

}

.siteheader_logo {
  display: block;
  margin-top: 8px;
  /* /* border-radius: 0px; */
  /* clear: both; */
  position: relative;
  box-sizing: border-box;
  /* z-index: 3; */
  height: 34px;
  width: auto;
  /* left: 40px; */
  /* border-radius: 10px; */
  cursor: pointer;
  color: white
}

.siteheader_logo.light {
  color: black;
}

.siteheader_clickable {
  pointer-events: auto;
  color: white;
  /* box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px 0px; */
  /* transition: all 0.25s ease 0s; */
}

.siteheader_clickable.light {
  color: black;
}

.siteheader_clickable.active {
  border-bottom: 2px;
  border-color: black;
  border-style: solid;
}

.siteheader_clickable.login {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background: none transparent;
  border: 2px solid rgb(54, 54, 54);
  text-align: center;
  box-sizing: border-box;
  height: auto;
  width: auto;
  font-weight: bold;
  color: rgb(54, 54, 54);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px 0px;
  transition: all 0.25s ease 0s;
}


.siteheader_clickable.register {

  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background: none rgb(54, 54, 54);
  border: 2px solid rgb(54, 54, 54);
  text-align: center;
  box-sizing: border-box;
  height: auto;
  width: auto;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px 0px;
  transition: all 0.25s ease 0s;
}

.siteheader_clickable.login:hover {

  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 10px 0px;
}

.siteheader_clickable.register:hover {

  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 10px 0px;
}