.sightings-slider {
    width: 100%;
    margin: 0 auto;
    max-height: 350px;
    background: linear-gradient(to bottom, #e07136 50%, #596550 50%);
    overflow: hidden;
    padding-top: 10px; /* Adds padding to center vertically */
    padding-bottom: 10px;
    justify-content: center;
}

.sightings-slider.centered .swiper-wrapper {
    justify-content: center;
}


.sighting-card {
    background-color: rgba(255, 255, 255, 0.9);
    /* Slightly transparent white */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 6px;
    margin-top: 2px;
    max-height: 350px;
    /* Ensure card height stays within slider's limit */
}

.sighting-image {
    width: 100%;
    height: 150px;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sighting-image img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
    /* Ensures the image fills the container, but it may crop the image to fit */
}

.sighting-info {
    /* padding: 10px; */
}

.sighting-info h5 {
    color: #333;
    padding: 10px;
}

.sighting-info p {
    color: #666;
    line-height: 1.4;
    font-size: 0.9em;
    padding: 2px;
}

/* Swiper pagination styles */
.swiper-pagination-bullet {
    background: #888;
}

.swiper-pagination-bullet-active {
    background: #333;
}

/* Responsive design */
@media (max-width: 768px) {

    .sighting-image {
        height: 150px;
        /* Adjusted height for smaller screens */
    }

    .sighting-image img {
        height: 150px;
        max-height: 150px;
        /* Further reduce height for mobile screens */
    }

    .sighting-info p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {

    .sighting-image img {
        height: 170px;
        max-height: 170px;
        /* Further reduce height for mobile screens */
    }

    .sighting-image {
        height: 170px;
        max-height: 170px;
        /* Further reduce height for mobile screens */
    }

    .sighting-info p {
        font-size: 0.8em;
    }
}